import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PageTitle({
  icon, children, noBorder, actions,
}) {
  return (
    <>
      <h4 className="mb-3 d-flex justify-content-between">
        <span>
          {icon && (<FontAwesomeIcon icon={icon} className="text-primary" />)}
          <span className="ms-2">{children}</span>
        </span>
        {actions && (
          <span>{actions}</span>
        )}
      </h4>
      {!noBorder && (<hr />)}
    </>
  );
}

PageTitle.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.string.isRequired,
  noBorder: PropTypes.bool,
  actions: PropTypes.node,
};

PageTitle.defaultProps = {
  icon: null,
  noBorder: false,
  actions: null,
};
