import React from 'react';

import dayjs from 'dayjs';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {
  useLoaderData,
} from 'react-router-dom';

import { Wrapper } from '@googlemaps/react-wrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faEnvelope,
  faLocationArrow,
} from '@fortawesome/free-solid-svg-icons';

import GoogleMap from '../../components/google-map/google-map';
import Calendar from '../../components/calendar/calendar';

export default function ProviderView() {
  const provider = useLoaderData();

  return (
    <Container>
      <Row>
        <Col sm={4}>
          <Card className="mb-3 shadow-sm">
            <Card.Img
              src={provider.Person?.Content?.path || 'https://www.jea.com/cdn/images/avatar/avatar-alt.svg'}
              variant="top"
            />
            <Card.Body>
              <Card.Title className="border-bottom pb-1">
                {provider.Person?.prefix}
                &nbsp;
                {provider.Person?.firstName}
                &nbsp;
                {provider.Person?.lastName}
                &nbsp;
                {provider.Person?.suffix}
              </Card.Title>
              <Row>
                <Col>
                  <Button size="sm" variant="info" className="me-2">
                    <FontAwesomeIcon icon={faPhone} />
                  </Button>
                  <Button size="sm" variant="info" className="me-2">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Button>
                  <Button size="sm" variant="info" className="me-2">
                    <FontAwesomeIcon icon={faLocationArrow} />
                  </Button>
                </Col>
              </Row>
              <Form>
                {provider.licenseType && (
                <Form.Group className="mb-2">
                  <Form.Label className="w-100 mb-0">License Type</Form.Label>
                  <Form.Text className="ps-1 w-100">{provider.licenseType}</Form.Text>
                </Form.Group>
                )}
                {provider.issueDate && (
                  <Form.Group className="mb-2">
                    <Form.Label className="w-100 mb-0">License Issued</Form.Label>
                    <Form.Text className="w-100 ps-1">{dayjs(provider.issueDate).format('YYYY-MM-DD')}</Form.Text>
                  </Form.Group>
                )}
                {provider.expirationDate && (
                  <Form.Group className="mb-2">
                    <Form.Label className="w-100 mb-0">License Expires</Form.Label>
                    <Form.Text className="w-100 ps-1">{dayjs(provider.expirationDate).format('YYYY-MM-DD')}</Form.Text>
                  </Form.Group>
                )}
                {provider.speciality && (
                  <Form.Group className="mb-2">
                    <Form.Label className="w-100 mb-0">Speciality</Form.Label>
                    <Form.Text className="w-100 ps-1">{provider.speciality}</Form.Text>
                  </Form.Group>
                )}
                {provider.institution && (
                  <Form.Group className="mb-2">
                    <Form.Label className="w-100 mb-0">Institution</Form.Label>
                    <Form.Text className="w-100 ps-1">{provider.institution}</Form.Text>
                  </Form.Group>
                )}
                {provider.degree && (
                  <Form.Group className="mb-2">
                    <Form.Label className="w-100 mb-0">Degree</Form.Label>
                    <Form.Text className="w-100 ps-1">{provider.degree}</Form.Text>
                  </Form.Group>
                )}
                {provider.graduationDate && (
                  <Form.Group className="mb-2">
                    <Form.Label className="w-100 mb-0">Graduation Date</Form.Label>
                    <Form.Text className="w-100 ps-1">{dayjs(provider.graduationDate).format('YYYY-MM-DD')}</Form.Text>
                  </Form.Group>
                )}

              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          {provider.Facilities?.map((facility) => (
            <Card>
              <Card.Body>
                <Card.Title>{facility.name}</Card.Title>
                <Form>
                  {!!facility.Phones?.length && (
                  <Form.Group className="mb-2">
                    <Form.Label className="w-100 mb-0">Phone</Form.Label>
                    <Form.Text className="d-block ps-1">{facility.Phones[0].number}</Form.Text>
                  </Form.Group>
                  )}
                  <Form.Group className="mb-2">
                    <Form.Label className="w-100 mb-0">Address</Form.Label>
                    <Form.Text className="d-block ps-1">{facility.Addresses[0].address1}</Form.Text>
                    {facility.Addresses[0].address2 && (<Form.Text className="d-block ps-1">{facility.Addresses[0].address2}</Form.Text>)}
                    {facility.Addresses[0].address3 && (<Form.Text className="d-block ps-1">{facility.Addresses[0].address3}</Form.Text>)}
                    <Form.Text className="d-block ps-1 mt-0">
                      {facility.Addresses[0].city}
                      ,
                      &nbsp;
                      {facility.Addresses[0].state}
                  &nbsp;
                      {facility.Addresses[0].postalCode}

                    </Form.Text>
                  </Form.Group>
                </Form>
                <Wrapper apiKey="AIzaSyBK_r6PZEmYGmIfT6F4orJnfj3YU9oVMDs">
                  <GoogleMap
                    center={{
                      lat: facility.Addresses[0].latitude,
                      lng: facility.Addresses[0].longitude,
                    }}
                  />
                </Wrapper>
              </Card.Body>
            </Card>
          ))}

          <Calendar />
        </Col>
      </Row>
    </Container>
  );
}
