import React from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

export default function DailyCheckin() {
  return (
    <ButtonGroup size="lg" className="w-100 px-3">
      <Button className="rounded-start-2 border-0">Worse</Button>
      <Button className="border-0">Good</Button>
      <Button className="rounded-end-2 border-0">Better</Button>
    </ButtonGroup>
  );
}
