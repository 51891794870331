import React from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default function PayView() {
  return (
    <Container>
      <Row>
        <Col sm={{ offset: 3, span: 6 }}>
          <Card className="mb-4">
            <Card.Body className="pt-4">
              <Card.Title className="text-center">Pay Now</Card.Title>
              <Card.Text className="text-center mb-4">
                You are checking in for care. Pay your copay now.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title>Amount: $20.00</Card.Title>
              <Button variant="primary" className="w-100">Confirm</Button>
              <Button variant="transparent" className="w-100">Cancel</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
