import React from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import {
  Link,
  useLoaderData,
} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import PageTitle from '../../../components/page-title/page-title';

export default function ProviderListView() {
  const providers = useLoaderData();

  return (
    <Container>
      <PageTitle
        noBorder
        actions={(
          <Button as={Link} to="/admin/provider">
            <FontAwesomeIcon icon={faPlus} />
            <span className="ms-2">Create</span>
          </Button>
        )}
      >
        Providers
      </PageTitle>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>Admin</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/providers' }} active>Providers</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Prefix</th>
                <th>Name</th>
                <th>Suffix</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              {providers.rows.map((provider) => (
                <tr key={provider.id} className="align-middle">
                  <td>{provider.Person?.prefix}</td>
                  <td>
                    {provider.Person?.firstName}
                    &nbsp;
                    {provider.Person?.lastName}
                  </td>
                  <td>{provider.Person?.suffix}</td>
                  <td>
                    <Button size="sm" as={Link} to={`/admin/provider/${provider.id}`}>Edit</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
