import React from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';

import dayjs from 'dayjs';

export default function CalendarItem({ weekDayOffset }) {
  const today = dayjs().date();
  const current = dayjs().startOf('week').add(weekDayOffset, 'day').date();
  const dayName = dayjs().startOf('week').add(weekDayOffset, 'day').format('ddd');
  const isToday = (current === today);
  return (
    <Col className="d-flex flex-column text-center border-0 rounded-0">
      <span className="mb-2 text-muted">{dayName}</span>
      <span className="position-relative text-muted">
        <span className={`${isToday ? 'text-light' : ''} position-relative`} style={{ zIndex: 2 }}>{current}</span>
        {isToday && (
          <span
            className="position-absolute shadow start-50 translate-middle-x rounded-circle bg-info"
            style={{
              zIndex: 1,
              width: '30px',
              height: '30px',
              top: '-3px',
            }}
          />
        )}
      </span>
    </Col>
  );
}

CalendarItem.propTypes = {
  weekDayOffset: PropTypes.number.isRequired,
};
