import React, {
  useEffect,
  useState,
} from 'react';
import {
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

import PermissionContext from '../../context/permission';
import ToastContext from '../../context/toast';
import PageHeader from '../page-header/page-header';
import PageNavigation from '../page-navigation/page-navigation';

export default function SiteLayout() {
  const user = useLoaderData();
  const [toast, setToast] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [showTour, setShowTour] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setShowToast(!!toast);
  }, [toast]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <PermissionContext.Provider value={user.permissions}>
      <ToastContext.Provider value={setToast}>

        <Container fluid>
          <Row>
            <Col lg={3} xl={2} className="pt-5 ps-3 bg-primary text-white d-none d-lg-flex min-vh-100">
              <PageNavigation variant="light" user={user} navigate={navigate} />
            </Col>
            <Col xs={12} lg={9} xl={10} className="content-wrapper p-0">
              <header data-testid="site-header">
                <PageHeader user={user} />
              </header>
              <Container className="pt-1 px-0">
                <Row>
                  <Col xl={{ offset: 2, span: 8 }}>
                    <main className="main mb-4" data-testid="site-main">
                      <Outlet
                        context={{
                          showTour,
                          setShowTour,
                          user,
                        }}
                      />
                    </main>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <ToastContainer
          position="top-end"
          className="me-3 mt-3 position-fixed"
        >
          <Toast
            show={showToast}
            onClose={() => setToast(null)}
            delay={4000}
            bg={toast?.bg}
            autohide
          >
            <Toast.Header closeButton>
              <strong className="me-auto">{toast?.header}</strong>
            </Toast.Header>
            <Toast.Body>{toast?.body}</Toast.Body>
          </Toast>

        </ToastContainer>
      </ToastContext.Provider>
    </PermissionContext.Provider>
  );
}
