import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import {
  Link,
  useNavigate,
} from 'react-router-dom';

import ActiveForm from '../../components/active-form/active-form';

export default function LoginView() {
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const loginForm = useMemo(() => ([
    {
      label: 'Email',
      name: 'email',
      placeholder: 'Email',
      required: true,
    },
    {
      label: 'Password',
      name: 'pass',
      type: 'password',
      placeholder: 'Password',
      required: true,
    },
  ]), []);

  const submitHandler = useCallback((formData) => {
    const fetchData = async () => {
      try {
        const res = await fetch('/api/user/auth', {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (res.status > 299) {
          setFormError('Invalid Login Information.');
          return;
        }

        const data = await res.text();

        window.sessionStorage.setItem('a', data);
        navigate('/sync');
      } catch (error) {
        setFormError('Invalid Login Information.');
      } finally {
        setIsLoading(false);
      }
    };

    setFormError('');
    setIsLoading(true);
    fetchData();
  }, []);

  return (
    <>
      <Navbar bg="white" expand="md" className="border-bottom mb-4">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img src="/images/QwikCare_logo.jpeg" alt="QwikCare" height="40px" style={{ borderRadius: '5px' }} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container className="login-view">
        <Row className="mb-4">
          <Col md={{ offset: 3, span: 6 }} className="position-relative">
            <ActiveForm
              focus
              floatingLabels
              isLoading={isLoading}
              onSubmit={submitHandler}
              title="Login"
              fields={loginForm}
              formError={formError}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Link to="/data-privacy" className="mx-auto mt-3 text-center">Privacy and Data Safety</Link>
          </Col>
        </Row>
      </Container>

    </>
  );
}
