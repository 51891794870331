import React from 'react';
import {
  createBrowserRouter,
  redirect,
} from 'react-router-dom';

import request from './utils/request';

import ErrorElement from './components/error-element/error-element';
import Redirect from './components/redirect/redirect';
import ProtectedRoute from './components/protected-route/protected-route';

import AdminCreateUserView from './views/admin/create-user';
import AdminHomeView from './views/admin/home';
import AdminUserView from './views/admin/user';
import AdminUsersView from './views/admin/users';
import ProviderCreateView from './views/admin/provider/create';
import ProviderEditView from './views/admin/provider/edit';

import SiteLayout from './components/site-layout/site-layout';
import HomeView from './views/home/home';
import MyDataView from './views/my-data/my-data';
import LoginView from './views/login/login';
import SyncView from './views/sync/sync';
import CheckSymptomsView from './views/check-symptoms/check-symptoms';
import FindCareView from './views/find-care/find-care';
import ProviderView from './views/provider/provider';

import PayView from './views/scanner/pay';
import ScannerView from './views/scanner/scanner';
import ConfirmView from './views/scanner/confirm';
import AccountView from './views/account/account';

import ComingSoonView from './views/coming-soon/coming-soon';
import ProviderListView from './views/admin/provider/list';
import DataSafetyView from './views/data-safety/data-safety';

const router = createBrowserRouter([
  {
    path: '/login',
    errorElement: <Redirect to="/login" />,
    element: <LoginView />,
  },
  {
    path: '/sync',
    element: <SyncView />,
  },
  {
    path: '/data-privacy',
    element: <DataSafetyView />,
  },
  {
    element: <SiteLayout />,
    errorElement: <ErrorElement />,
    id: 'site',
    loader: () => request('/api/user'),
    children: [
      {
        path: '/',
        element: <HomeView />,
      },
      {
        path: '/my-data',
        element: <MyDataView />,
      },
      {
        path: '/check-symptoms',
        element: <CheckSymptomsView />,
      },
      {
        path: '/notifications',
        element: <ComingSoonView />,
      },
      {
        path: '/my-health',
        element: <ComingSoonView />,
      },
      {
        path: '/scan',
        element: <ScannerView />,
      },
      {
        path: 'scan/confirm',
        element: <ConfirmView />,
      },
      {
        path: 'scan/pay',
        element: <PayView />,
      },
      {
        path: '/find-care',
        element: <FindCareView />,
        loader: () => Promise.all([
          request('/api/providers/specialities'),
        ]),
      },
      {
        path: '/provider/:providerId',
        element: <ProviderView />,
        loader: ({ params }) => request(`/api/provider/${params.providerId}`),
      },
      {
        path: '/social-groups',
        element: <ComingSoonView />,
      },
      {
        path: '/calendar',
        element: <ComingSoonView />,
      },
      {
        path: '/social-groups',
        element: <ComingSoonView />,
      },
      {
        path: '/account',
        element: <AccountView />,
        loader: () => request('/api/user/me'),
      },

      {
        path: '/admin',
        element: <ProtectedRoute />,
        children: [
          {
            path: '/admin',
            element: <AdminHomeView />,
          },
          {
            path: '/admin/users',
            element: <AdminUsersView />,
            loader: () => request('/api/admin/user'),
          },
          {
            path: '/admin/user/:userId',
            element: <AdminUserView />,
            loader: ({ params }) => request(`/api/admin/user/${params.userId}`),
          },
          {
            path: '/admin/user',
            element: <AdminCreateUserView />,
          },
          {
            path: '/admin/provider',
            element: <ProviderCreateView />,
            action: (async ({ request: req }) => {
              const formData = await req.formData();
              const res = await request('/api/provider', {
                method: 'POST',
                body: Object.fromEntries(formData),
              });
              return redirect(`/admin/provider/${res.id}`);
            }),
          },
          {
            path: '/admin/provider/:providerId',
            element: <ProviderEditView />,
            loader: ({ params }) => request(`/api/provider/${params.providerId}`),
            action: (async ({ request: req, params }) => {
              const formData = await req.formData();
              return request(`/api/provider/${params.providerId}`, {
                method: 'PUT',
                body: Object.fromEntries(formData),
              });
            }),
          },
          {
            path: '/admin/providers',
            element: <ProviderListView />,
            loader: () => request('/api/providers'),
          },
        ],
      },
    ],
  },
]);

export default router;
