import React from 'react';
import PropTypes from 'prop-types';

import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';

export default function NavigationCard({
  icon,
  title,
  // description,
  onClick,
  className,
  alertCount,
}) {
  return (
    <Card
      className={`shadow border-0 w-100 h-100 ${className}`}
      role="button"
      onClick={onClick}
    >
      {!!alertCount && (<Badge pill className="position-absolute px-2 bg-danger" style={{ left: '-10px', top: '-8px' }}>3</Badge>)}
      <Card.Img src={icon} variant="top" style={{ width: '40px', height: '40px' }} className="m-auto mt-3" />
      <Card.Body className="pb-1 pb-sm-3 pt-1 d-flex justify-content-center">
        <Card.Title className="text-center mt-auto text-sm-start navigation-card__title">{title}</Card.Title>
        {/* <Card.Text className="text-muted d-none d-sm-block">{description}</Card.Text> */}
      </Card.Body>
    </Card>
  );
}

NavigationCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  alertCount: PropTypes.number,
};

NavigationCard.defaultProps = {
  className: '',
  alertCount: 0,
};
