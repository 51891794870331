import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';

import {
  Link,
} from 'react-router-dom';

export default function DataSafetyView() {
  return (
    <>
      <Navbar bg="white" expand="md" className="border-bottom mb-4">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img src="/images/QwikCare_logo.jpeg" alt="QwikCare" height="40px" style={{ borderRadius: '5px' }} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <Row>
          <Col>
            <h1 className="text-light mb-1">Welcome to QwikCare</h1>
            <Link to="/login" className="mx-auto mt-3 text-center text-light">Back to Login</Link>
            <Card className="mt-3 mb-3">
              <Card.Body>
                <Card.Title>Experience Our Features Safely:</Card.Title>
                <Card.Text>
                  Currently, QwikCare is in a demo phase.
                  This means all accounts and interactions within
                  the app are simulations for demonstration purposes only.
                  No real personal data or identifiable information is
                  collected or displayed in this demo version.
                </Card.Text>

                <Card.Title>Data Use and Privacy:</Card.Title>
                <Card.Text>
                  We store simulated preferences and interactions with our
                  Large Language Models (LLMs) to showcase the app&apos;s capabilities.
                  This data is kept on a secure server and is purely for demo purposes.
                  As this is a demo, the information you see and interact with does not
                  correspond to real individuals or user accounts.
                </Card.Text>

                <Card.Title>Your Privacy is Our Priority:</Card.Title>
                <Card.Text>
                  Even in this demo phase, we&apos;re committed to data safety and privacy.
                  All simulated data is handled with the same level of security and
                  confidentiality as we would with real user data.
                  <br />
                  <br />
                  We aim to provide a realistic preview of QwikCare while ensuring transparency
                  and privacy.

                </Card.Text>
                <Card.Title>Feedback and Support:</Card.Title>
                <Card.Text>
                  Your feedback is valuable! It helps us improve and prepare for our full launch.
                  Please share your thoughts or report issues via
                  &nbsp;
                  <a href="mailto:support@fullstackers.com">support@fullstackers.com</a>
                  .
                </Card.Text>
                <Card.Title>Stay Tuned:</Card.Title>
                <Card.Text>
                  We&apos;re excited to move beyond the demo phase. To stay updated on our progress
                  and real launch, please visit our website.
                </Card.Text>
              </Card.Body>
            </Card>
            <Link to="/login" className="mx-auto mt-3 text-center">Back to Login</Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}
