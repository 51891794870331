import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import request from '../../utils/request';
import PageTitle from '../../components/page-title/page-title';

export default function AccountView() {
  const account = useLoaderData();
  const [file, setFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const fileRef = useRef(null);
  const navigate = useNavigate();

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(async (e) => {
    e.preventDefault();
    setIsDragging(false);

    const { files } = e.dataTransfer;

    if (files.length > 0) setFile(files[0]);
  }, []);

  useEffect(() => {
    if (!file || isProcessing) return;

    const formData = new FormData();
    formData.append('file', file);

    const sendData = async () => {
      await request('/api/user/content', {
        method: 'POST',
        formData,
      });
      setIsProcessing(false);
      navigate('.', { replace: true });
    };

    setIsProcessing(true);
    sendData();
  }, [file]);

  return (
    <Container>
      <PageTitle>Account Settings</PageTitle>
      <Row>
        <Col md={4}>
          <Card className="mb-3 shadow-sm">
            <Card.Img
              src={account.People[0]?.Content?.path || 'https://www.jea.com/cdn/images/avatar/avatar-alt.svg'}
              variant="top"
            />
            <Card.Body>
              <Card.Title className="text-center">
                {account.People[0]?.prefix}
                &nbsp;
                {account.People[0]?.firstName}
                &nbsp;
                {account.People[0]?.lastName}
                &nbsp;
                {account.People[0]?.suffix}
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title>Settings</Card.Title>
              <hr />

              <Row className="align-items-center">
                <Col sm={3}>Upload Photo</Col>
                <Col sm={9}>
                  <Card
                    bg={isDragging ? 'success' : 'secondary'}
                    className="bordered"
                    style={{ borderStyle: 'dotted', borderWidth: '4px' }}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={() => { if (!isProcessing) fileRef.current.click(); }}
                    role="button"
                  >
                    <Card.Body className="text-center">
                      {isProcessing && (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      )}
                      {!isProcessing && (
                      <Card.Text>
                        <FontAwesomeIcon icon={faUpload} />
                        <span className="ms-1">Drag and Drop or Click to upload</span>
                        <input
                          className="d-none"
                          type="file"
                          ref={fileRef}
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </Card.Text>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
