import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import dayjs from 'dayjs';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

export default function Calendar() {
  const [currentMonth, setCurrentMonth] = useState(dayjs().month());
  const [activeDay, setActiveDay] = useState(null);
  const [activeTime, setActiveTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const getOrdinalSuffix = useCallback((n) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }, []);

  const saveAppointment = useCallback((/* day, time */) => {
    setTimeout(() => {
      setLoading(false);
      setActiveDay(null);
      setActiveTime(null);
      setSaved(true);
    }, 1500);
    setLoading(true);
  });

  const times = useMemo(() => {
    let startTime = dayjs().hour(8).minute(0);
    const endTime = dayjs().hour(17).minute(0);

    // Create an array to hold the times
    const timeList = [];

    // Loop from start time to end time, adding each hour to the array
    while (startTime.isBefore(endTime) || startTime.isSame(endTime)) {
      timeList.push(startTime.format('h:mm A'));
      startTime = startTime.add(1, 'hour');
    }

    return timeList;
  }, []);

  const weekDays = useMemo(() => {
    const d = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 7; i++) {
      d.push(dayjs().day(i).format('ddd'));
    }
    return d;
  }, []);

  const getDaysInMonth = (year, month) => {
    const startOfMonth = dayjs().year(year).month(month).startOf('month');
    const daysInMonth = startOfMonth.daysInMonth();

    // Get an array of day numbers
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    // Calculate the start day of the week
    const startDay = startOfMonth.day();

    // Fill the days array with empty slots before the start day
    const calendarDays = Array.from({ length: startDay }, () => null).concat(days);

    return calendarDays;
  };

  const days = getDaysInMonth(dayjs().year(), dayjs().month(currentMonth).month());
  const weeks = [];
  for (let i = 0; i < days.length; i += 7) {
    weeks.push(days.slice(i, i + 7));
  }

  if (saved) {
    return (
      <Card bg="success" text="light">
        <Card.Body className="text-center">
          <h3>Your appointment has been sent to the provider to be confirmed!</h3>
        </Card.Body>
      </Card>
    );
  }

  if (loading) {
    return (
      <Card>
        <Card.Body>
          <Card.Text>
            <FontAwesomeIcon icon={faSpinner} spin />
            {' '}
            Sending appointment...
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>Schedule Appointment</Card.Header>
        <Card.Body>
          <Row className="mb-1 border-bottom pb-3">
            <Col className="text-center">
              <Button
                size="sm"
                variant="info"
                className="me-2"
                onClick={() => setCurrentMonth(dayjs().month(currentMonth).subtract(1, 'month').month())}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <Button
                size="sm"
                variant="transparent"
                className="me-2"
              >
                <span className="fw-bold">{dayjs().month(currentMonth).format('MMMM')}</span>
              </Button>
              <Button
                size="sm"
                variant="info"
                className="ms-2"
                onClick={() => setCurrentMonth(dayjs().month(currentMonth).add(1, 'month').month())}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </Col>
          </Row>

          <table style={{
            borderCollapse: 'separate',
            borderSpacing: '10px 10px',
            margin: '0 auto',
          }}
          >
            <thead>
              <tr>
                {weekDays.map((day) => (
                  <th key={day}>{day}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {weeks.map((week) => (
                <tr key={week}>
                  {week.map((day) => (
                    <td
                      key={day}
                      className="text-center"
                    >
                      {day && (
                      <Button
                        variant={
                          // eslint-disable-next-line no-nested-ternary
                          dayjs().date() === day ? 'info'
                            : activeDay === day ? 'primary' : 'transparent'
                        }
                        size="sm"
                        onClick={() => setActiveDay(day)}
                      >
                        {day || ''}
                      </Button>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Card.Body>
      </Card>
      {activeDay && (
      <Card>
        <Card.Body>
          <Card.Title>
            <i>
              {dayjs().month(currentMonth).format('MMMM')}
              {' '}
              {getOrdinalSuffix(activeDay)}
            </i>
          </Card.Title>
          <ListGroup className="overflow-auto mb-3 bg-light" style={{ maxHeight: '280px' }}>
            {times.map((time) => (
              <ListGroup.Item key={time} className="border-0">
                <Button
                  variant={activeTime === time ? 'primary' : 'transparent'}
                  style={{ minWidth: '200px' }}
                  className="border"
                  onClick={() => setActiveTime(time)}
                >
                  {time}
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button
            variant="primary"
            className="ms-3"
            style={{ minWidth: '200px' }}
            disabled={!activeTime}
            onClick={() => saveAppointment(activeDay, activeTime)}
          >
            Schedule
          </Button>
        </Card.Body>
      </Card>
      )}

    </>
  );
}
