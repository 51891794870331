import React from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {
  Link,
  Form as RouterForm,
} from 'react-router-dom';

import {
  faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';

import PageTitle from '../../../components/page-title/page-title';

export default function ProviderCreateView() {
  return (
    <Container>
      <PageTitle icon={faPenToSquare} noBorder>Create Provider</PageTitle>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>Admin</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/providers' }} active>Providers</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title className="mb-3">Provider Information</Card.Title>
              <Form as={RouterForm} method="POST">
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">First Name</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="firstName"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Middle Name</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="middleName"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Last Name</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="lastName"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Prefix</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="prefix"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Suffix</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="suffix"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Maiden Name</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="maidenName"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Date of Birth</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="dob"
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Gender</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Select
                        name="gender"
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Nationality</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="nationality"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button type="submit">Submit</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
