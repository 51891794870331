import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';

import {
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome';

import {
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

import {
  useNavigate,
} from 'react-router-dom';

export default function Purchase() {
  const [showBrands, setShowBrands] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const navigate = useNavigate();

  return (
    <Container className="perscription-purchase-view">
      <Row className="my-4">
        <Col className="d-flex flex-column align-items-center">
          <Image src="/icons/capsule.png" alt="Capsule" width="60" height="60" />
          {/* eslint-disable-next-line */}
          <h1 role="button" onClick={() => setShowBrands(!showBrands)}>
            <span>Gabapentin</span>
            <FontAwesomeIcon icon={faCaretDown} size="sm" className="ms-2" />
          </h1>

          {showBrands && (
            <div className="mt-3">
              <p className="text-muted mb-0">Select Brand</p>
              <div className="d-flex flex-row overflow-auto w-100 text-nowrap">
                <Button variant="secondary" className="px-4">Horizant</Button>
                <Button variant="secondary" className="px-4 ms-2">Gralise</Button>
                <Button variant="secondary" className="px-4 ms-2">Neurontin</Button>
                <Button variant="secondary" className="px-4 ms-2">Vibramycin</Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 2, span: 8 }}>
          <ListGroup>
            <ListGroup.Item
              className="border rounded shadow-sm mb-3 px-4 py-3"
              onClick={() => setSelectedLocation('CVS Pharmacy')}
              active={selectedLocation === 'CVS Pharmacy'}
            >
              <Row>
                <Col className="d-flex flex-column">
                  <h4 className="mb-2 fw-bold perscription-header">CVS Pharmacy</h4>
                  <p className="text-muted mb-0">1234 W. 36 Street</p>
                  <p className="text-muted mb-0">Madison, WI 19873</p>
                </Col>
                <Col className="d-flex flex-column align-items-end justify-content-center">
                  <h3 className="perscription-price">$103.45</h3>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item
              className="border rounded shadow-sm mb-3 px-4 py-3"
              onClick={() => setSelectedLocation('Costco')}
              active={selectedLocation === 'Costco'}
            >
              <Row>
                <Col className="d-flex flex-column">
                  <h4 className="mb-2 fw-bold perscription-header">Costco</h4>
                  <p className="text-muted mb-0">43 Lincoin Avenue</p>
                  <p className="text-muted mb-0">Madison, WI 19874</p>
                </Col>
                <Col className="d-flex flex-column align-items-end justify-content-center">
                  <h3 className="perscription-price">$112.88</h3>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item
              className="border rounded shadow-sm mb-3 px-4 py-3"
              onClick={() => setSelectedLocation('Walgreens')}
              active={selectedLocation === 'Walgreens'}
            >
              <Row>
                <Col className="d-flex flex-column">
                  <h4 className="mb-2 fw-bold perscription-header">Walgreens</h4>
                  <p className="text-muted mb-0">West Towne Mall</p>
                  <p className="text-muted mb-0">Madison, WI 19874</p>
                </Col>
                <Col className="d-flex flex-column align-items-end justify-content-center">
                  <h3 className="perscription-price">$124.51</h3>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item
              className="border rounded shadow-sm mb-3 px-4 py-3"
              onClick={() => setSelectedLocation('Walgreens2')}
              active={selectedLocation === 'Walgreens2'}
            >
              <Row>
                <Col className="d-flex flex-column">
                  <h4 className="mb-2 fw-bold perscription-header">Walgreens</h4>
                  <p className="text-muted mb-0">1000 N. 10th St</p>
                  <p className="text-muted mb-0">Madison, WI 19874</p>
                </Col>
                <Col className="d-flex flex-column align-items-end justify-content-center">
                  <h3 className="perscription-price">$156.04</h3>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
          <Button
            variant="primary"
            className="w-100 mt-3"
            disabled={!selectedLocation}
            onClick={() => navigate('/my-data')}
          >
            Confirm Purchase
          </Button>
        </Col>
      </Row>

    </Container>
  );
}
