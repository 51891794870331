import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import {
  useNavigate,
} from 'react-router-dom';

import {
  faLock,
} from '@fortawesome/free-solid-svg-icons';

import PageTitle from '../../components/page-title/page-title';
import NavigationCard from '../../components/navigation-card/navigation-card';

export default function AdminHomeView() {
  const navigate = useNavigate();

  return (
    <Container>
      <PageTitle icon={faLock}>Admin Navigation</PageTitle>
      <Row xs={1} md={2}>
        <Col>
          <NavigationCard
            icon="👨‍⚕️"
            title="Provider Management"
            description="Create and manage Provider for Find Care"
            onClick={() => navigate('/admin/providers')}
          />
        </Col>
        {/* }
        <Col>
          <NavigationCard
            icon="👨‍⚕️"
            title="User Management"
            description="Create and manage Users"
            onClick={() => navigate('/admin/users')}
          />
        </Col>
  */}
      </Row>
    </Container>
  );
}
