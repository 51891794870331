import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import {
  Link,
  useNavigate,
} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faBell,
} from '@fortawesome/free-regular-svg-icons';

import PageNavigation from '../page-navigation/page-navigation';

export default function PageHeader({ user }) {
  const [showNav, setShowNav] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = useCallback((path) => {
    setShowNav(false);
    navigate(path);
  });

  return (
    <Navbar expand={false} className="p-0 page-header mb-2">
      <Container fluid className="position-relative">
        <Navbar.Brand as={Link} to="/">
          <img
            src="/images/QwikCare_logo.jpeg"
            alt="QwikCare"
            height="40px"
            style={{ borderRadius: '5px' }}
            className="d-none"
          />
        </Navbar.Brand>
        <div className="d-flex flex-row py-3 px-2">
          <Link to="/notifications" className="position-relative nav-link ms-auto me-3">
            <Badge pill className="position-absolute px-2 bg-danger" style={{ left: '-10px', top: '-2px' }}>3</Badge>
            <FontAwesomeIcon icon={faBell} size="2x" className="text-white" />
          </Link>
          <Navbar.Toggle aria-controls="page-header-offcanvas" className="border-0 d-sm-block d-lg-none" onClick={() => setShowNav(true)} />
        </div>
        <Navbar.Offcanvas
          id="page-header-offcanvas"
          placement="end"
          show={showNav}
          onHide={() => setShowNav(false)}
        >
          <Offcanvas.Header closeButton />
          <Offcanvas.Body>
            <PageNavigation user={user} navigate={handleNavigate} />
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

PageHeader.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string),
    People: PropTypes.arrayOf(PropTypes.shape({
      Content: PropTypes.shape({
        path: PropTypes.string,
      }),
    })),
  }),
};

PageHeader.defaultProps = {
  user: null,
};
