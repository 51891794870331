import React, {
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

export default function GoogleMap({
  center,
  zoom,
}) {
  const ref = useRef();
  useEffect(() => {
    // eslint-disable-next-line no-new
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
      scaleControl: false,
      zoomControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      panControl: false,
      rotateControl: false,
    });

    // eslint-disable-next-line no-new
    new window.google.maps.Marker({
      position: center,
      map,
    });
  });

  return <div ref={ref} id="map" style={{ width: '100%', height: '300px' }} />;
}

GoogleMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
};

GoogleMap.defaultProps = {
  center: {
    lat: 39.9612,
    lng: -82.9988,
  },
  zoom: 10,
};
