import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  // useMemo,
} from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
// import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';

import InfiniteScroll from 'react-infinite-scroller';

import {
  Link,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar as faStarFull,
  faMagnifyingGlass,
  faSpinner,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons';

import {
  faStar,
  faStarHalfStroke,
} from '@fortawesome/free-regular-svg-icons';

import request from '../../utils/request';

export default function FindCareView() {
  const navigate = useNavigate();
  const [
    specialities,
  ] = useLoaderData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [providers, setProviders] = useState({
    count: 0,
    rows: [],
  });
  const [providerName, setProviderName] = useState('');
  const [loading, setLoading] = useState(false);
  const providerNameRef = useRef(null);
  const speciality = searchParams.get('speciality');
  const searchRef = useRef(null);

  useEffect(() => {
    if (!navigator.geolocation) return;

    navigator.geolocation.getCurrentPosition((position) => {
      setSearchParams({
        ...searchParams,
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      });
    });
  }, []);

  const refresh = useCallback((page) => {
    const fetchData = async () => {
      const d = await request(
        `/api/providers?name=${providerName}&speciality=${speciality || ''}&page=${page}`,
      );

      setProviders((previousValues) => ({
        count: d.count,
        rows: [
          ...previousValues.rows,
          ...d.rows,
        ],
      }));
      setLoading(false);
    };

    setLoading(true);
    fetchData();
  }, [providerName, speciality]);

  useEffect(() => {
    if (searchRef.current) clearTimeout(searchRef.current);

    setLoading(true);
    setProviders({
      count: 0,
      rows: [],
    });

    searchRef.current = setTimeout(() => {
      refresh(1);
    }, 500);
  }, [providerName, specialities]);

  return (
    <Container className="find-care-view">
      <Row className="heading-row mb-3 px-3">
        <h1 className="fw-bold" style={{ fontSize: '2.5rem' }}>Find Care</h1>
        <h5 className="mb-4">
          <FontAwesomeIcon icon={faLocationDot} />
          <span className="ms-2">Madison, WI</span>
        </h5>
      </Row>
      <Row className="mb-3">
        <Col className="px-0">
          <Card className="border-0 pb-4 rounded-top-5">
            <Card.Body className="bg-white rounded-top-5 border-0 pt-4 pb-0">
              <Form>
                <Form.Group className="mb-4">
                  <InputGroup className="shadow rounded-start-1 rounded-end-1">
                    <InputGroup.Text className="bg-white rounded-start-1 border-0 ps-4">
                      <FontAwesomeIcon icon={faMagnifyingGlass} className="text-primary" />
                    </InputGroup.Text>
                    <FloatingLabel
                      label="Search conditions, doctors..."
                    >
                      <Form.Control
                        placeholder="Search doctors..."
                        ref={providerNameRef}
                        value={providerName}
                        className="border-0 text-dark rounded-end-1 placeholder-dark"
                        onChange={(e) => setProviderName(e.target.value)}
                      />
                    </FloatingLabel>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-2">
                  <Form.Label className="d-flex flex-row justify-content-between mb-3">
                    <span>
                      <span className="text-primary">Browse by Specialty</span>
                      {speciality && (
                        <>
                      &nbsp;
                          <Button
                            size="sm"
                            variant="link"
                            className="pt-0"
                            onClick={(e) => setProviderName(e.target.value)}
                          >
                            Clear
                          </Button>
                        </>
                      )}
                    </span>
                    <Link to="/" className="text-dark text-decoration-none">See All</Link>
                  </Form.Label>
                  <Row className="overflow-auto d-flex flex-row flex-nowrap px-0 flex-lg-wrap" style={{ scrollbarWidth: 'none' }}>
                    {specialities.map((s) => (
                      <Col key={s.id} className="mb-3 d-flex align-items-stretch">
                        <Card className="h-100 shadow border-0" style={{ minWidth: '200px' }}>
                          <Card.Body>
                            <span style={{ fontSize: '3rem' }} className="mb-2">{s.icon}</span>
                            <Card.Title className="fw-bold mb-0">{s.name}</Card.Title>
                            <Card.Text className="text-dark">
                              {s.providers || 0}
                              {' '}
                              Specialists
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        {/*
                        <NavigationCard
                          icon={s.icon}
                          title={s.name}
                          description={`${s.providers || 0} Specialists`}
                          className={speciality === s.id ? 'border-info' : ''}
                          onClick={() => {
                            setSearchParams({
                              speciality: s.id,
                              name: providerName,
                            });
                          }}
                        />
                        */}
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="mb-3 text-primary">
        My Doctors (
        {loading ? (<FontAwesomeIcon icon={faSpinner} spin />) : providers.count.toLocaleString()}
        )
      </div>
      <Row>
        <Col>
          <InfiniteScroll
            pageStart={1}
            loadMore={refresh}
            hasMore={providers.rows.length < providers.count}
            loader={(
              <h4 className="text-center">
                <FontAwesomeIcon icon={faSpinner} spin />
                <span className="ms-3">Loading...</span>
              </h4>
            )}
          >
            {providers.rows.map((provider) => (
              <Card className="mb-3 bg-light border-0" key={`provider-${provider.id}`} role="button" onClick={() => navigate(`/provider/${provider.id}`)}>
                <Card.Body className="d-flex flex-row align-items-center">
                  <Image
                    src={provider.Person?.Content?.path || 'https://www.jea.com/cdn/images/avatar/avatar-alt.svg'}
                    className="object-fit-contain"
                    roundedCircle
                    style={{ height: '80px', width: '80px' }}
                  />
                  <div className="ms-3">
                    <Card.Title className="fw-bold" style={{ fontSize: '1rem' }}>
                      {!!provider.Person?.prefix && `${provider.Person.prefix} `}
                      {provider.Person?.firstName}
                    &nbsp;
                      {provider.Person?.lastName}
                    &nbsp;
                      {provider.Person?.suffix}
                    </Card.Title>
                    <Card.Subtitle>
                      {
                      provider.Facilities?.map(
                        (facility) => facility.FacilitySpecialities?.map(
                          (s) => s.name,
                        ),
                      )
                    }
                    </Card.Subtitle>
                  </div>
                  <div className="ms-auto me-1 text-center">
                    <div>
                      <FontAwesomeIcon icon={faStarFull} className="text-primary" />
                      <FontAwesomeIcon icon={faStarFull} className="text-primary" />
                      <FontAwesomeIcon icon={faStarFull} className="text-primary" />
                      <FontAwesomeIcon icon={faStarHalfStroke} className="text-primary" />
                      <FontAwesomeIcon icon={faStar} className="text-primary" />
                    </div>
                    <span>3.5 / 5</span>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </InfiniteScroll>
        </Col>
      </Row>
    </Container>
  );
}
