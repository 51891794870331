import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faMagnifyingGlass,
  faHouse,
  faQrcode,
  faFolderOpen,
  faUserGroup,
  faHeartPulse,
  faNotesMedical,
  faShare,
  faCalendar,
  faKey,
  faUser,
  faCircleQuestion,
  faRightFromBracket,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

export default function PageNavigation({ variant, user, navigate }) {
  const textColor = variant === 'light' ? 'text-white' : 'text-secondary';

  const handleLogout = useCallback(() => {
    window.sessionStorage.removeItem('a');
    navigate('/login');
  });

  return (
    <Nav className="navbar-nav mt-5">
      <Nav.Link onClick={() => navigate('/')} className="w-100" active>
        <FontAwesomeIcon icon={faHouse} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Home</span>
      </Nav.Link>
      <Nav.Link onClick={() => navigate('/scan')} className="w-100">
        <FontAwesomeIcon icon={faQrcode} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Scan</span>
      </Nav.Link>
      <hr />
      <Nav.Link onClick={() => navigate('/my-data')} className="w-100">
        <FontAwesomeIcon icon={faFolderOpen} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">My Data</span>
      </Nav.Link>
      <Nav.Link onClick={() => navigate('/check-symptoms')} className="w-100">
        <FontAwesomeIcon icon={faMagnifyingGlass} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Virtual Doctor</span>
      </Nav.Link>
      <Nav.Link onClick={() => navigate('/social-groups')} className="w-100">
        <FontAwesomeIcon icon={faUserGroup} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Social Groups</span>
      </Nav.Link>
      <Nav.Link onClick={() => navigate('/my-health')} className="w-100">
        <FontAwesomeIcon icon={faHeartPulse} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">My Health</span>
      </Nav.Link>
      <Nav.Link onClick={() => navigate('/find-care')} className="w-100">
        <FontAwesomeIcon icon={faNotesMedical} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Find Care</span>
      </Nav.Link>
      <hr />
      <Nav.Link className="w-100">
        <FontAwesomeIcon icon={faShare} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Share Records</span>
      </Nav.Link>
      <Nav.Link className="w-100">
        <FontAwesomeIcon icon={faCalendar} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Appointments</span>
      </Nav.Link>
      <Nav.Link className="w-100">
        <FontAwesomeIcon icon={faKey} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Authorized Providers</span>
      </Nav.Link>
      <hr />
      {!!user.permissions && !!user.permissions.length && (
        <Nav.Link className="w-100" onClick={() => navigate('/admin')}>
          <FontAwesomeIcon icon={faLock} className={textColor} style={{ minWidth: '22px' }} />
          <span className="ms-3">Admin</span>
        </Nav.Link>
      )}
      <Nav.Link onClick={() => navigate('/account')} className="w-100">
        <FontAwesomeIcon icon={faUser} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Account Settings</span>
      </Nav.Link>
      <Nav.Link className="w-100">
        <FontAwesomeIcon icon={faCircleQuestion} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Help</span>
      </Nav.Link>
      <Nav.Link className="w-100" onClick={handleLogout}>
        <FontAwesomeIcon icon={faRightFromBracket} className={textColor} style={{ width: '22px' }} />
        <span className="ms-3">Logout</span>
      </Nav.Link>
    </Nav>
  );
}

PageNavigation.propTypes = {
  variant: PropTypes.string,
  user: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  navigate: PropTypes.func.isRequired,
};

PageNavigation.defaultProps = {
  variant: null,
  user: null,
};
