import React, {
  useEffect,
  useState,
  useRef,
} from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';

import {
  Form as RouterForm,
  Link,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';

import request from '../../../utils/request';
import PageTitle from '../../../components/page-title/page-title';

export default function ProviderEditView() {
  const { providerId } = useParams();
  const provider = useLoaderData();
  const fileRef = useRef(null);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    const sendData = async () => {
      await request(`/api/provider/${providerId}/content`, {
        method: 'POST',
        formData,
      });
      navigate('.', { replace: true });
    };

    sendData();
  }, [file]);

  return (
    <Container>
      <PageTitle icon={faPenToSquare} noBorder>Edit Provider</PageTitle>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>Admin</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/providers' }}>Providers</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/admin/provider/${providerId}` }} active>Edit</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col sm={4}>
          <Card className="mb-3 shadow-sm">
            <Card.Img
              src={provider.Person?.Content?.path || 'https://www.jea.com/cdn/images/avatar/avatar-alt.svg'}
              variant="top"
            />
            <Card.Body>
              <Card.Title>
                {provider.Person?.prefix}
                &nbsp;
                {provider.Person?.firstName}
                &nbsp;
                {provider.Person?.lastName}
                &nbsp;
                {provider.Person?.suffix}
              </Card.Title>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-end">
              <Button variant="link" onClick={() => fileRef.current.click()}>Update Photo</Button>
              <input
                type="file"
                className="d-none"
                accept="image/*"
                onChange={(e) => setFile(e.target.files[0])}
                ref={fileRef}
              />
            </Card.Footer>
          </Card>
        </Col>
        <Col sm={8}>
          <Card className="mb-3 shadow-sm">
            <Card.Body>
              <Card.Title className="mb-3">Provider Information</Card.Title>
              <Form as={RouterForm} method="PUT">
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">First Name</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="firstName"
                        required
                        defaultValue={provider.Person?.firstName}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Middle Name</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="middleName"
                        defaultValue={provider.Person?.middleName}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Last Name</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="lastName"
                        required
                        defaultValue={provider.Person?.lastName}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Prefix</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="prefix"
                        defaultValue={provider.Person?.prefix}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Suffix</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="suffix"
                        defaultValue={provider.Person?.suffix}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Maiden Name</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="maidenName"
                        defaultValue={provider.Person?.maidenName}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Date of Birth</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="dob"
                        type="date"
                        defaultValue={provider.Person?.dob?.split('T')[0]}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Gender</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Select
                        name="gender"
                        defaultValue={provider.Person?.gender}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Nationality</Form.Label>
                  <Col sm={10}>
                    <Form.Group>
                      <Form.Control
                        name="nationality"
                        defaultValue={provider.Person?.nationality}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button type="submit">Submit</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
