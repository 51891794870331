import React, {
  useCallback,
} from 'react';

import { Scanner } from '@yudiel/react-qr-scanner';

export default function ScannerView() {
  const isValidURL = useCallback((url) => {
    try {
      // eslint-disable-next-line no-new
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  const handleScan = useCallback((data) => {
    if (!data || !data.length) return;

    if (data[0].format !== 'qr_code') return;

    if (!isValidURL(data[0].rawValue)) return;

    // window.location = data[0].rawValue;
    window.location = '/scan/confirm';
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h1>Scan QR Code</h1>
      <Scanner onScan={handleScan} />
    </div>
  );
}
