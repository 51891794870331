import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import dayjs from 'dayjs';

import CalendarItem from './calendar-item';

export default function CalendarRow() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="p-0">
          <Card className="calendar-row rounded-top-5 rounded-bottom-0 shadow-none pb-3 px-2">
            <Card.Header className="bg-white rounded-top-5 border-0 fw-bold pt-4 pb-0" style={{ fontSize: '1.2rem' }}>{dayjs().format('MMMM')}</Card.Header>
            <Card.Body>
              <Row xs={7}>
                <CalendarItem weekDayOffset={0} />
                <CalendarItem weekDayOffset={1} />
                <CalendarItem weekDayOffset={2} />
                <CalendarItem weekDayOffset={3} />
                <CalendarItem weekDayOffset={4} />
                <CalendarItem weekDayOffset={5} />
                <CalendarItem weekDayOffset={6} />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
